<template>
<div id="play_space_container">
  <div class="connections">
    <canvas id="app-canvas"></canvas>
  </div>
</div>
</template>
 
<script>
//import * as PIXI from 'pixi.js'
import { mapState } from "vuex"; //imports user data

export default {
    name: "SimpleMachines",
    computed: {
        ...mapState(['userData']),
        ...mapState(['activeProfile']),
    },
    methods: {
        unsubscribePusher() {
            if (this.$pusher) {
                // console.log('Unsubscribe pusher...');
                this.$pusher.unsubscribe(this.cname);
            }
        },
        async init(){
            //let 
            let isHost = this.activeProfile.host; // true if they are the host.
            let userToken = this.$store.getters.token;
            let trackingService = this.tracking;
            //let thechannel = this.$pusher;
            let thechannel = await this.$pusher.subscribe(this.cname);
            /* global SimpleMachinesApp */
            let StartApp = function(scope) {
                // let scale = window.innerHeight<768 ? window.innerHeight/768 : 1;  // Measure window height and scale down if necessary
                let scale = 1;                                                       // Or just set the scale to 1

                let app = new SimpleMachinesApp(scale, '/simple-machines/src');
                app.setup();
                // MOVED Pusher setup so that it's shared across the apps
                scope.channel = thechannel;//thepusher.subscribe(cname);
                app.setPusherChannel(scope.channel);
                app.setTrackingService(trackingService);
            }

            // Load public js scripts
            //
            if (typeof SimpleMachinesApp === 'function') {
                StartApp(this);
            }
            else {
                // console.log('Loading SimpleMachinesApp...');
                let scriptsLoaded = 0;

                // Load scripts
                let scriptNames = [
                    "/simple-machines/src/libs/liquidfun_google.js",
                    "/simple-machines/src/libs/pixi-5.3.5.min.js",
                    "/simple-machines/src/libs/pixi-filters.js",
                    "/simple-machines/src/utils.js",
                    "/simple-machines/src/ui/LoadScreen.js",
                    "/simple-machines/src/ui/IntroScreen.js",
                    "/simple-machines/src/ui/ScrollContainer.js",
                    "/simple-machines/src/ui/UIUtils.js",
                    "/simple-machines/src/components/computils.js",
                    "/simple-machines/src/components/ball.js",
                    "/simple-machines/src/components/seesaw.js",
                    "/simple-machines/src/components/shape.js",
                    "/simple-machines/src/components/shuttle.js",
                    "/simple-machines/src/components/dispenser.js",
                    "/simple-machines/src/components/neonsign.js",
                    "/simple-machines/src/components/platform.js",
                    "/simple-machines/src/app.js"
                ];
                for (let i = 0; i < scriptNames.length; i++) {
                    // let divScripts = document.getElementById('load-script');
                    let script = document.createElement('script');
                    script.src = scriptNames[i];
                    script.async = false; // This is required for synchronous execution
                    document.head.appendChild(script);
                    let scope = this;
                    script.addEventListener('load', function() {
                        if (++scriptsLoaded === scriptNames.length) {
                            // Everything was loaded, start the app
                            StartApp(scope);
                        }
                    });
                }
            }
        }
    },
    data() {
        return {
            cname: 'presence-smapp-' + this.$route.params.space_id,
            channel: null
        }
    },
    created() {
        window.addEventListener('beforeunload', this.unsubscribePusher());
    },
    destroyed() {
        window.removeEventListener('beforeunload', this.unsubscribePusher());
    },
    mounted() {
        this.tracking({
            type: 'page',
            title: '/playspace/simplemachines',
        });        
        this.init();
    }
};
</script> 

<style lang='scss'>

</style>